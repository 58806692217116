.ql-toolbar.ql-snow {
  border: none!important;
  border-bottom: 1px solid #ccc!important;
}
.ql-container {
  border: none!important;
  border-bottom: 1px solid #ccc!important;
}
.ql-tooltip {
  left: unset!important;
}

.quill.richTextEditor .ql-editor {
  max-height: 400px;
}

.quill.richSurveyEmailEditor .ql-editor {
  min-height: 300px;
}